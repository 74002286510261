import {  qs,  } from 'martha'
import * as cache from '../lib/cache'

export default () => ({
  count: 0,
  showSearch: false,
  showSort: false,
  form: null,
  parser: new DOMParser(),
  listing: null,
  countEl: null,
  skeleton: null,
  popping: false,
  activeFilter: null,
  searchInput: null,
  activeSearch: null,
  openFilter: null,
  activeSort: 'Latest',
  init() {
      // Directly use DOM references for form and listing
      this.form = this.$refs.form;
      this.form = this.$refs.form;
      this.listing = this.$refs.listing;
      this.countEl = this.$refs.count;
      this.countMobileEl = this.$refs.countMobile;
      const tileCount = this.listing.querySelectorAll('.listing-tile').length;
      this.count = tileCount;

      // Sync form state with URL
      this.syncWithUrl();

      // Event listeners for popstate
      window.addEventListener('popstate', () => {
          this.popping = true;
          this.syncWithUrl();
          this.handleChange(false);
          this.popping = false;
      });
  },
  toggleFilter(id) { 
    this.openFilter = this.openFilter === id ? null : id
  },
  toggleSort() {
    this.showSort = !this.showSort
  },
  syncWithUrl() {
      // Sync radio buttons with URL parameters
      const searchParams = new URLSearchParams(window.location.search);

      // const paramString = searchParams.toString()
      const searchParam = searchParams.get('s');
      const typeParam = searchParams.get('type');

      if (searchParam) {
        this.activeSearch = searchParam;
        this.searchInput = searchParam;
      }

      const inputs = this.form.querySelectorAll('input[name="filter"]');
      inputs.forEach(input => {
        if (typeParam?.split(',').includes(input.value.split('=')[1])) {
            input.checked = true;
        }
      });
      this.activeFilter = typeParam ? typeParam : null;
  },
  selectResourceCategory(slug) {
    if (this.popping) return;

   this.activeFilter = slug

    const [key, value] = ['type', slug]

    // Update URL
    const newUrl = window.location.pathname + '?' + key + '=' + encodeURIComponent(value);
    window.history.pushState({}, '', newUrl);

    // Fetch and update listing
    this.fetchListing();
    this.syncWithUrl();
  },
  applyFilters(pushState = true) {
    if (this.popping) return;

    const selectedInputs = this.form.querySelectorAll('input[name="filter"]:checked');
    this.activeSearch = null
    this.searchInput = null

    if (!selectedInputs) {
      this.openFilter = null;
      return;
    };

    const key = [...selectedInputs].map(input => input.value.split('=')[0])[0]
    const selectedValues = [...selectedInputs].map(input => input.value.split('=')[1])
    const selectedValuesString = selectedValues.join(',')

    this.activeFilter = selectedValuesString

    if (pushState) {
        const newUrl = window.location.pathname + '?' + key + '=' + encodeURIComponent(selectedValuesString);
        window.history.pushState({}, '', newUrl);
    }

    // // Fetch and update listing
    this.fetchListing();
  },
  handleSearch(pushState = true) {
    if (this.popping) return;

    // Extract form data
    const searchTerm = this.searchInput;
    if (!searchTerm) {
      this.showSearch = false;
      return
    };

    // Update URL
    if (pushState) {
        const newUrl = window.location.pathname + '?s=' + encodeURIComponent(searchTerm);
        window.history.pushState({}, '', newUrl);
    }

    // Fetch and update listing
    this.showSearch = false;
    this.activeSearch = searchTerm;
    this.searchInput = null;
    this.fetchListing();
  },
  handleSort(e) {
    if (this.popping) return;
    const sortValue = e.target.value
    const sortLabel = e.target.dataset.optionName
    this.activeSort = sortLabel

    const sortOrder = sortValue === 'oldest' ? 'ASC' : 'DESC'

    const sortBy = 'publish_date'
    const sortString = `orderby=${sortBy}&order=${sortOrder}`
    
    const currentParams = new URLSearchParams(window.location.search)
    const typeAndSearchParams = [...currentParams.entries()].filter(param => !param[0].startsWith('order'))
    const typeAndSearchParamsString = new URLSearchParams(typeAndSearchParams).toString()
    const sortParamsString = `${typeAndSearchParamsString}&${sortString}`

    const newUrl = window.location.pathname + '?' + sortParamsString;
    window.history.pushState({}, '', newUrl);

    this.fetchListing();
    this.syncWithUrl();
    this.showSort = false
    return 
  },
  get activeFilterTitles  () {
    const inputs = [...this.form.querySelectorAll('input[name="filter"]')];

    let typeList = this.activeFilter ? decodeURIComponent(this.activeFilter).split(',') : [];

    const titles =  typeList.map(type => {
      const input = inputs.find(input => {
        return input.value.split('=')[1] === type
      });

      return input?.dataset.optionName;
    })

    return titles;
  },
  get activeFilterTypes  () {
    return this.activeFilter.split('=')[0];
  },
  clearFilters() {
    const inputs = this.form.querySelectorAll('input[name="filter"]');
    inputs.forEach(input => {
        input.checked = false;
    });
    this.activeFilter = null;
    this.activeSearch = null
    const newUrl = window.location.pathname;
    window.history.pushState({}, '', newUrl);

    this.fetchListing();
  },
  removeFilter(filterToRemove) {
    const inputs = this.form.querySelectorAll('input[name="filter"]');
    let slugToRemove
    let key
    inputs.forEach(input => {
      if (input.dataset.optionName === filterToRemove) {
        input.checked = false;
        slugToRemove = input.value.split('=')[1]
        key = input.value.split('=')[0]
      }
    });

    this.activeFilter = this.activeFilter.split(',').filter(slug => slug !== slugToRemove).join(',')

    if (this.activeFilter === '') { 
      this.clearFilters()
      return 
    }


    const newUrl = window.location.pathname + '?' + key + '=' + encodeURIComponent(this.activeFilter);
    window.history.pushState({}, '', newUrl);

    this.fetchListing();

  },
  toggleSearch() {
    this.showSearch = !this.showSearch;
  },
  async fetchListing() {
    try {
      const newElements = await cache.get(async () => {
        const html = await fetch(window.location.href).then(res => res.text())
        const doc = this.parser.parseFromString(html, 'text/html')
        const newListing = qs('.js-listing', doc.body)
        const newCount = qs('.js-count', doc.body)
        const newCountMobile = qs('.js-count-mobile', doc.body)
        return {newListing, newCount, newCountMobile} 
      }, window.location.href)

      const { newListing, newCount, newCountMobile } = newElements

      this.listing.innerHTML = newListing.innerHTML;
      this.countEl.innerHTML = newCount.innerHTML;
      if(newCountMobile && this.countMobileEl) {
        this.countMobileEl.innerHTML = newCountMobile?.innerHTML;
      }

    } catch (error) {
        console.error('Error:', error);
        // Handle error appropriately
    } finally {
        this.form.classList.remove('pointer-events-none', 'opacity-70');
    }
  },
})