import lottie from 'lottie-web';

export default ({
    animationSegments
}) => ({
    // activeIndex: 0,
    animtion: null,
    activeIndex: 0,
    datapointCount: 0,
    animation: null,
    init() {
        this.datapointCount = this.$el.getAttribute('data-datapoint-count');

        const animationPath = this.$refs.desktopAnimation.getAttribute('data-animation-path');
        this.animation = lottie.loadAnimation({
            container: this.$refs.desktopAnimation, // Current element
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: animationPath // Use the passed animation path
        });

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                // Check if more than 50% of the element is visible
                if (entry.intersectionRatio > 0.5) {
                    // Extract index from element ID
                    const index = entry.target.id.split('-')[1];
                    this.activeIndex = parseInt(index);
                    this.animation.playSegments([animationSegments[this.activeIndex].startFrame, animationSegments[this.activeIndex].endFrame], true);

                }
            });
        }, {
            threshold: [0.5]  // Trigger when 50% visibility is crossed
        });

        // Observe all datapoint elements
        this.$el.querySelectorAll('[id^="datapoint-"]').forEach((element) => {
            observer.observe(element);
        }); 


        // this.animation.addEventListener('DOMLoaded', () => {
        //     this.animation.playSegments([startFrame, endFrame], true);
        // });
    },
    // get activeAspectRatio() {
    //     const activeElement = document.getElementById(`datapoint-${this.activeIndex}`);
    //     return activeElement ? activeElement.dataset.aspectRatio * 100 : 0;
    // },
});
