import 'plyr/dist/plyr.css'
import '../styles/main.css'
import 'focus-visible'
import { listen } from 'quicklink'
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

// stores 
import header from './stores/header'

// components
import resourcesCarousel from './components/resourcesCarousel'
import whoWeServe from './components/whoWeServe'
import testimonials from './components/testimonials'
import listing from './components/listing'
import resourceListing from './components/resourceListing'
import workflowTimeline from './components/workflowTimeline'
import whyTrubridge from './components/whyTrubridge'
import imageGallery from './components/imageGallery'
import videoPlayer from './components/videoPlayer'
import videoSection from './components/videoSection'

import dropdown from './components/dropdown'
import productFilter from './components/productFilter'
// import ninjaforms from './components/ninjaforms'

const createApp = () => {
  Alpine.store("header", header);

  Alpine.data("resourcesCarousel", resourcesCarousel);
  Alpine.data("whoWeServe", whoWeServe);
  Alpine.data("testimonials", testimonials);
  Alpine.data("listing", listing);
  Alpine.data("resourceListing", resourceListing);
  Alpine.data("productFilter", productFilter);
  // Alpine.data("resourceFilter", resourceFilter);
  Alpine.data("workflowTimeline", workflowTimeline);
  Alpine.data("whyTrubridge", whyTrubridge);
  Alpine.data("imageGallery", imageGallery);
  Alpine.data("videoPlayer", videoPlayer);
  Alpine.data("videoSection", videoSection);


  Alpine.data("dropdown", dropdown);

  Alpine.plugin(collapse);
  // Alpine.plugin(focus);

  if (process.env.NODE_ENV === 'production') listen()

  // console.log('ninjaforms')
  // ninjaforms()

  window.Alpine = Alpine
  Alpine.start();
};

createApp();