import choozy from 'choozy'
import { each, on, remove, qs, add } from 'martha'
import * as cache from '../lib/cache'

export default () => ({
  count: 0,
  form: null,
  parser: new DOMParser(),
  listing: null,
  countEl: null,
  skeleton: null,
  popping: false,
  activeFilter: null,
  init() {
      // Directly use DOM references for form and listing
      this.form = this.$refs.form;
      this.listing = this.$refs.listing;
      this.countEl = this.$refs.count;
      const tileCount = this.listing.querySelectorAll('.listing-tile').length;
      this.count = tileCount;

      // Sync form state with URL
      this.syncWithUrl();

      // Event listeners for popstate
      window.addEventListener('popstate', () => {
          this.popping = true;
          this.syncWithUrl();
          this.handleChange(false);
          this.popping = false;
      });
  },
  syncWithUrl() {
      // Sync radio buttons with URL parameters
      const searchParams = new URLSearchParams(window.location.search);

      const paramString = searchParams.toString()

      const inputs = this.form.querySelectorAll('input[name="filter"]');
      inputs.forEach(input => {
          if (paramString === input.value) {
              input.checked = true;
          }
      });
      this.activeFilter = paramString;
  },
  selectResourceCategory(slug) {
    if (this.popping) return;

   this.activeFilter = slug

    const [key, value] = ['type', slug]

    // Update URL
    const newUrl = window.location.pathname + '?' + key + '=' + encodeURIComponent(value);
    window.history.pushState({}, '', newUrl);

    // Fetch and update listing
    this.fetchListing();
    this.syncWithUrl();
},
  handleChange(pushState = true) {
      if (this.popping) return;

      // Extract form data
      const selectedInput = this.form.querySelector('input[name="filter"]:checked');
      if (!selectedInput) return;
      this.activeFilter = selectedInput.value

      const [key, value] = selectedInput.value.split('=')

      // Update URL
      if (pushState) {
          const newUrl = window.location.pathname + '?' + key + '=' + encodeURIComponent(value);
          window.history.pushState({}, '', newUrl);
      }

      // Fetch and update listing
      this.fetchListing();
  },
  get activeFilterTitle  () {
    const inputs = [...this.form.querySelectorAll('input[name="filter"]')];
    const matchingInput = inputs.find(input => input.value === this.activeFilter);
    return matchingInput ? matchingInput.getAttribute('data-option-name') : '';
  },
  get activeFilterType  () {
    return this.activeFilter.split('=')[0];
  },
  clearFilters() {
    const inputs = this.form.querySelectorAll('input[name="filter"]');
    inputs.forEach(input => {
        input.checked = false;
    });
    this.activeFilter = null;
    const newUrl = window.location.pathname;
    window.history.pushState({}, '', newUrl);

    this.fetchListing();
  },
  async fetchListing() {

    try {
    const newElements = await cache.get(async () => {
        const html = await fetch(window.location.href).then(res => res.text())
        const doc = this.parser.parseFromString(html, 'text/html')
        const newListing = qs('.js-listing', doc.body)
        const newCount = qs('.js-count', doc.body)
        return {newListing, newCount} 
    }, window.location.href)

    // console.log({newElements, newCount})
    const { newListing, newCount } = newElements

    // const tileCount = newListing.querySelectorAll('.listing-tile').length;
    // this.count = tileCount;

    console.log(this.count, { newCount})
    this.listing.innerHTML = newListing.innerHTML;
    this.countEl.innerHTML = newCount.innerHTML;
    } catch (error) {
        console.error('Error:', error);
        // Handle error appropriately
    } finally {
        this.form.classList.remove('pointer-events-none', 'opacity-70');
    }
  },
})