import Player from '@vimeo/player';

export default () => ({
    playing: false,
    playerId: null,
    init() {
        const options = {
            id: this.$refs.videoEl.dataset.videoId,
            width: '100%',
            controls: false, // This line hides the player controls
        };
        this.playerId = `player-${this.$refs.videoEl.dataset.videoId}`

        window[this.playerId] = new Player(this.$refs.videoEl, options);
    },
    play() {
        if (window[this.playerId]) {
            window[this.playerId].play().then(() => {
                this.playing = true
            }).catch((error) => {
                console.error('Error trying to play the video:', error);
            });
        }
    },
    pause() {
        if (window[this.playerId]) {
            window[this.playerId].pause().then(() => {
                this.playing = false
            }).catch((error) => {
                console.error('Error trying to pause the video:', error);
            });
        }
    },
    get buttonText() {
        return this.playing ? 'Pause' : 'Play';
    },
    destroy() {
        if (window[this.playerId]) {
            window[this.playerId].unload().then(() => {
                console.log('Video has been unloaded');
            }).catch((error) => {
                console.error('Error unloading the player:', error);
            });
        }
    }
});