import Plyr from 'plyr'



export default () => ({
  player: null,
  init() {
      this.player = new Plyr(this.$el, {
          tooltips: { controls: false, seek: false },
          controls: [
              'play-large',
            //   'play',
              // 'progress',
              // 'current-time',
              // 'mute',
              // 'captions',
              // 'fullscreen',
          ],
      });
  },
  destroy() {
      if (this.player) {
          this.player.destroy();
      }
  }
});