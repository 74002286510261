import Swiper from 'swiper';
import {  Scrollbar } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/scrollbar';

export default () => ({
  initSwiper() {
    new Swiper(this.$refs.swiperContainer, {
      modules: [ Scrollbar],
      slidesPerView: 'auto',
      spaceBetween: 24,
      scrollbar: {
        el: this.$refs.swiperPagination,
        draggable: true,
        hide: false,
      },
    })
  },
});
