import Swiper from 'swiper';
import {  Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';

export default () => ({
  swiper: null,
  activeSlide: 0,

  initSwiper() {
    this.swiper = new Swiper(this.$refs.swiperContainer, {
      modules: [Pagination, Autoplay],
      slidesPerView: 1,
      loop: true,
      on: {
        realIndexChange: () => {
          this.activeSlide = this.swiper.realIndex;
        },
      },
      // autoplay: {
      //   delay: 6000,
      //   disableOnInteraction: false,
      // },
      pagination: {
        el: this.$refs.swiperPagination,
        clickable: true,
      },
    });
  },

  setSlide(index) { 
    if (this.swiper) {
      this.swiper.slideToLoop(index);
      // Reset the autoplay timer when manually changing slides
      this.autoplayTimeLeft = 3000;
      this.swiper.autoplay.start();
    }
  },
});