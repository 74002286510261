import Headroom from "headroom.js";
import debounce from 'lodash.debounce';
import Cookies from 'js-cookie'

export default {
  isOpen: false,
  isMobileMenuOpen: false,
  activeMobileMenu: null,
  activeLink: false,
  menu: null,
  bannerDismissed: false,
  
  setMenu: debounce(function(menu) {
    this.menu = menu;
    this.isOpen = true;
    document.body.classList.add('overflow-hidden');
    document.documentElement.classList.remove('overflow-x-hidden');
  }, 100),

  closeMenu() {
    if (this.setMenu.cancel) this.setMenu.cancel();
    this.isOpen = false;
    this.isMobileMenuOpen = false;
    document.body.classList.remove("overflow-hidden");
    document.documentElement.classList.add("overflow-x-hidden");  
    this.activeMobileMenu = null;
  },
  toggleMobileMenu ()  {
    if (this.isMobileMenuOpen) {
      document.body.classList.remove("overflow-hidden");
      document.documentElement.classList.add("overflow-x-hidden");  
      this.activeMobileMenu = null
      this.isMobileMenuOpen = false
    } else {
      document.body.classList.add('overflow-hidden');
      document.documentElement.classList.remove('overflow-x-hidden');
      this.isMobileMenuOpen = true
    }
  },
  setActiveMobileMenu(menu) {
    if (this.activeMobileMenu === menu) {
      this.activeMobileMenu = null;
    } else {
      this.activeMobileMenu = menu;
    }
  },
  setLinkHovered(menu) {
    this.activeLink = menu;
    this.isOpen = false;
  },
  clearLinkHovered() {
    this.activeLink = false;
  },
  hideBanner() {
    this.bannerDismissed = true;
    // Optionally, you can still use Cookies.set here if needed
    Cookies.set('tb-hide-banner', 'true', { expires: 1 });
  },
  init() {
    this.bannerDismissed = Cookies.get('tb-hide-banner') === 'true';
    const desktopHeader = document.querySelector(".desktop-header");
    const mobileHeader = document.querySelector(".mobile-header");
    const desktopHeadroom  = new Headroom(desktopHeader);
    const mobileHeadroom  = new Headroom(mobileHeader);
    desktopHeadroom.init();
    mobileHeadroom.init();
  },
};
