
export default (id) => ({
  get isActive() {
      return (Alpine.store('header').isOpen && Alpine.store('header').menu === id) || Alpine.store('header').activeLink === id ;
  },
  get isDropdownInactive() {
    return (Alpine.store('header').isOpen && Alpine.store('header').menu !== id && Alpine.store('header').menu !== 'contact-trigger' ) || Alpine.store('header').activeLink;
  },
  get isLinkInactive() {
    return (Alpine.store('header').isOpen && Alpine.store('header').menu !== 'contact-trigger' );
},
});
