import Swiper from 'swiper';
import {  Scrollbar } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/scrollbar';

export default () => ({
  initSwiper(imageSize) {
    new Swiper(this.$refs.swiperContainer, {
      modules: [ Scrollbar],
      slidesPerView: 1,
      spaceBetween: 16,
      scrollbar: {
        el: this.$refs.swiperPagination,
        draggable: true,
        hide: false,
      },
      breakpoints: {
        800: {
          slidesPerView: 'auto',
          spaceBetween: imageSize === "full-width" ? 40 : 24,

        }
      },
    })
    
  },
});