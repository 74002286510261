import Swiper from 'swiper';
import {  Scrollbar, EffectFade } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

export default (id) => ({
  modules: [ Scrollbar],
  buttonSwiper: null,
  cardSwiper: null,
  activeIndex: 0,
  setActiveIndex(index) {
    this.activeIndex = index
    this.cardSwiper.slideTo(index)
  },
  initSliders ()  { 
    setTimeout(() => {
      this.buttonSwiper = new Swiper(this.$refs.swiperContainer, {
        modules: [ Scrollbar],
        slidesPerView: 'auto',
        spaceBetween: 40,
        scrollbar: {
          el: this.$refs.swiperPagination,
          draggable: true,
          hide: false,
        },
        breakpoints: {
          800: {
            spaceBetween: 64
          }
        },
      })

      this.cardSwiper = new Swiper(this.$refs.swiperCardContainer, {
        modules: [ EffectFade],
        effect: 'fade',
        slidesPerView: 1,
        noSwipingClass: 'noswipe',
      });
    }, 100)

  }
});
