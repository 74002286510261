
export default (id) => ({
  open: false,
  toggle() {
    if (this.open) {
      return this.close()
    }
    this.$refs.button.focus()
    document.body.classList.add("overflow-hidden");
    document.documentElement.classList.remove("overflow-x-hidden");
    this.open = true
  },
  close(focusAfter) {
    if (!this.open) return
    this.open = false
    document.body.classList.remove("overflow-hidden");
    document.documentElement.classList.add("overflow-x-hidden");
    focusAfter && focusAfter.focus()
  }
});
